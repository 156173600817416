<script>
  import axios from "axios";

  export default {
    data(){
      return{
        list:[]
      }
    },
    methods:{
      async fillData(){
        const res=await axios.post(process.env.VUE_APP_BASE_API + "verifyLog/list",)
        if(res.data.code!=200){
          this.$message.error(res.data.message)
          return;
        }
        this.list=res.data.data;
        console.log(this.list)
      },
      getTimeStr(timestamp){
        let date = new Date(timestamp);
        return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      },
      async exportAll(){
        await this.$http.post( "verifyLog/export",{},{
          responseType: "blob"
        })
      }
    },
    created(){
      this.fillData()
    }
  }
</script>

<template>
  <div id="root">
    <div>
      <el-button size="small" type="primary" @click="exportAll">导出数据</el-button>
    </div>
    <el-table size="mini" border style="margin-top: 20px;" height="100%" :data="list">
      <el-table-column type="index" width="80px" label="序号"/>
      <el-table-column label="手机号" width="150px" prop="mobile"/>
      <el-table-column label="IP" prop="host"/>
      <el-table-column label="所属客服ID" prop="serverId"/>
      <el-table-column label="验证时间" prop="host">
          <template slot-scope="scope">{{getTimeStr(scope.row.verifyDate)}}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="scss">
#root{
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>